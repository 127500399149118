import React, { useState } from "react";
import { HashRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./components/Home";
import SignUp from "./components/SignUp";
import Gallery from "./components/Gallery";
// import Timetable from "./components/Timetable";
import Teacher from "./components/Teacher";
import Contacts from "./components/Contacts";
import "./App.css";

const images = [
  "/images/1.jpg",
  "/images/2.jpg",
  "/images/3.jpg",
  "/images/4.jpg",
  "/images/5.jpg",
  "/images/6.jpg",
  "/images/8.jpg",
  "/images/9.jpg",
  "/images/13.jpg",
];

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <Router>
      <div className="App">
        <header>
          <div className="menu-toggle" onClick={toggleMenu}>
            ☰
          </div>
          <nav className={`nav ${menuOpen ? "open" : ""}`}>
            <Link to="/" onClick={closeMenu}>
              Главная
            </Link>
            <Link to="/signUp" onClick={closeMenu}>
              Записаться
            </Link>
            <Link to="/gallery" onClick={closeMenu}>
              Фотоальбом
            </Link>
            {/* <Link to="/timetable" onClick={closeMenu}>
              Расписание
            </Link> */}
            <Link to="/teacher" onClick={closeMenu}>
              Художественный руководитель
            </Link>
            <Link to="/contacts" onClick={closeMenu}>
              Контакты
            </Link>
          </nav>
          {/* .................. */}
          <div className="header-contact">
            <p className="header-phone">
              <a href="tel:+79082056208" title="Звоните и записывайтесь!">
                <img
                  src="images/call.png"
                  alt="Телефон"
                  width="25"
                  height="25"
                />
              </a>
            </p>
            <ul className="header-social">
              <li>
                <a
                  href="https://www.instagram.com/ballet_in_the_city/"
                  title="Инстаграм"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="images/instagram.png"
                    alt="инстаграм"
                    width="25"
                    height="25"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://vk.com/ballet_tete"
                  title="ВКонтакте"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="images/vk.png"
                    alt="вконтакте"
                    width="25"
                    height="25"
                  />
                </a>
              </li>
            </ul>
          </div>
        </header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signUp" element={<SignUp />} />
          <Route path="/gallery" element={<Gallery images={images} />} />
          {/* <Route path="/timetable" element={<Timetable />} /> */}
          <Route path="/teacher" element={<Teacher />} />
          <Route path="/contacts" element={<Contacts />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
