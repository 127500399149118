import React from "react";
import "./Teacher.css";

const Teachers = () => (
  <div className="teachers" id="teachers">
    <h2>Художественный руководитель</h2>
    <div className="teacher-info">
      <h3>Андрей Асиньяров</h3>
      <p>
        Андрей Асиньяров окончил Красноярский хореографический колледж в 1994
        году по специальности "Артист балета". Он прошел обучение в классе
        заслуженной артистки КБР Розы Хакуловой. После выпуска был приглашен в
        Санкт-Петербургскую консерваторию им. Н.А. Римского-Корсакова в качестве
        ведущего солиста.
      </p>
      <p>
        Далее работал в Государственном театре оперы и балета Республики Коми,
        где выступал на одной сцене с Майей Плисецкой. Вернувшись в Красноярск,
        стал ведущим солистом Красноярского государственного театра оперы и
        балета, где проработал почти 20 лет.
      </p>
      <p>
        Андрей сотрудничал с выдающимися балетмейстерами, такими как Юрий
        Григорович и Владимир Васильев. Он гастролировал в Японии, Италии,
        Испании и многих других странах, исполняя главные партии в балетах
        "Лебединое озеро", "Ромео и Джульетта", "Щелкунчик" и многих других.
      </p>
      <p>
        За свои заслуги был удостоен благодарностей от Правительства Москвы и
        Министерства Культуры РФ, а также получил признание на театральном
        конкурсе «Золотая маска».
      </p>
      <p>
        В настоящее время Андрей преподает балет, делясь своим опытом и знаниями
        со всеми желающими, от любителей до профессионалов.
      </p>
    </div>
  </div>
);

export default Teachers;
