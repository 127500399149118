import React, { useState, useEffect } from "react";
import { Parallax } from "react-parallax";
import "./Home.css";
import TrialFormModal from "./TrialFormModal"; // Импортируем модальное окно

const Home = () => {
  const [visible, setVisible] = useState({
    paragraph: false,
    item1: false,
    item2: false,
    item3: false,
    item4: false,
    item5: false,
  });

  const [isModalOpen, setIsModalOpen] = useState(false); // Состояние для модального окна

  useEffect(() => {
    const timers = [];
    timers.push(
      setTimeout(
        () => setVisible((prev) => ({ ...prev, paragraph: true })),
        1000
      )
    );
    timers.push(
      setTimeout(() => setVisible((prev) => ({ ...prev, item1: true })), 4000)
    );
    timers.push(
      setTimeout(() => setVisible((prev) => ({ ...prev, item2: true })), 7000)
    );
    timers.push(
      setTimeout(() => setVisible((prev) => ({ ...prev, item3: true })), 10000)
    );
    timers.push(
      setTimeout(() => setVisible((prev) => ({ ...prev, item4: true })), 13000)
    );
    timers.push(
      setTimeout(() => setVisible((prev) => ({ ...prev, item5: true })), 16000)
    );

    return () => timers.forEach((timer) => clearTimeout(timer));
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Parallax
        bgImage="images/7.jpg"
        bgImageStyle={{
          height: "100vh",
          objectFit: "cover",
        }}
        strength={100}
      >
        <div className="about-section" id="about">
          <div className={`text-block ${visible.paragraph ? "show" : ""}`}>
            <p>
              Балетная школа <strong>"TÈTE"</strong> ориентирована на взрослых и
              детей, новичков, любителей и профессионалов.
            </p>
          </div>
          <ul>
            <li className={visible.item1 ? "show" : ""}>
              Занятия классическим балетом для начинающих с ноля
            </li>
            <li className={visible.item2 ? "show" : ""}>
              Детское направление для развития артистов балета
            </li>
            <li className={visible.item3 ? "show" : ""}>
              Класс пуантов для девушек с опытом
            </li>
            <li className={visible.item4 ? "show" : ""}>
              Репертуарный класс для взрослых
            </li>
            <li className={visible.item5 ? "show" : ""}>
              Индивидуальные занятия
            </li>
          </ul>
          <div className="signup-container">
            <button className="signup-button" onClick={handleOpenModal}>
              Записаться
            </button>
            <p className="signup-text">Запишитесь на БЕСПЛАТНОЕ ЗАНЯТИЕ</p>
          </div>
        </div>
      </Parallax>
      <TrialFormModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </>
  );
};

export default Home;
