import React, { useState } from "react";
import TrialFormModal from "./TrialFormModal";
import "./SignUp.css";

const SignUp = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState([]);

  const schedule = {
    "Группа начинающих": [
      "ВТ. 19:00 - 20:00",
      "ЧТ. 19:00 - 20:00",
      "ВС. 13:00 - 14:00",
    ],
    "Группа продолжающих": [
      "ВТ. 20:00 - 21:00",
      "ЧТ. 20:00 - 21:00",
      "ВС. 13:00 - 14:00",
    ],
    "Группа ДЕТИ В БАЛЕТЕ с 6 лет": ["ВТ. 18:00 - 19:00", "ВС. 12:00 - 13:00"],
  };

  const openScheduleModal = (times) => {
    setModalContent(times);
  };

  const closeModal = () => {
    setModalContent([]);
  };

  return (
    <>
      <h3 className="signup-heading">Выбери свою группу</h3>
      <div className="timetable-section">
        {Object.entries(schedule).map(([group, times]) => (
          <div className="group-card" key={group}>
            <h3>{group}</h3>
            <p>Описание программы...</p>
            <button
              className="signup-button"
              onClick={() => setIsModalOpen(true)}
            >
              Записаться
            </button>
            <button
              className="schedule-button"
              onClick={() => openScheduleModal(times)}
            >
              Расписание
            </button>
          </div>
        ))}
      </div>

      {/* Модальное окно для расписания */}
      {modalContent.length > 0 && (
        <div className="modal-background" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h3>Расписание</h3>
            <ul>
              {modalContent.map((time, index) => (
                <li key={index}>{time}</li>
              ))}
            </ul>
            <button className="close-button" onClick={closeModal}>
              Закрыть
            </button>
          </div>
        </div>
      )}

      {/* Модальное окно для записи */}
      <TrialFormModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default SignUp;
