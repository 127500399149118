import React from "react";
import "./Contacts.css";

const Contacts = () => (
  <div className="contacts" id="contacts">
    <h2>Контакты</h2>

    {/* Карта Яндекс */}
    <div className="map-container">
      <iframe
        src="https://yandex.ru/map-widget/v1/?ll=92.851388%2C56.013908&z=18.79&whatshere%5Bpoint%5D=92.851107%2C56.014126&whatshere%5Bzoom%5D=17"
        width="100%"
        height="400"
        frameBorder="0"
        allowFullScreen
        title="Карта местоположения"
      ></iframe>
    </div>

    <p>
      <a href="tel:+79082056208" className="contact-phone">
        +7 908 205 62 08
      </a>
    </p>

    {/* Социальные сети */}
    <div className="socials">
      <a
        href="https://www.instagram.com/ballet_in_the_city/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Instagram
      </a>
      <a
        href="https://vk.com/ballet_tete"
        target="_blank"
        rel="noopener noreferrer"
      >
        VK
      </a>
    </div>
  </div>
);

export default Contacts;
